export const menuList = [{
  header: 'SETTINGS',
  submenu: [{
    name: "My Account",
    url: "/my-account",
    access: "MY_ACCOUNT"
  }]
}, {
  header: 'MARKETING',
  submenu: [{
    name: "Referral links",
    url: "/referral-links",
    access: "AFFILIATE_LINKS"
  }]
}];