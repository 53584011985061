function getFormattedDate(date) {
  const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
  const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);

  return `${day}.${month}.${date.getFullYear()}`;
};

function toFormattedNumber(value) {
  return Number(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

module.exports = {
  getFormattedDate,
  toFormattedNumber
};